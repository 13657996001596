import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import LogoMobile from './../../../assets/images/logo-mobile.png';
import Logo from './../../../assets/images/logo.png';
import Image from 'react-bootstrap/Image'
import SideBarList from "./SideBarList";
import Scrollbar from 'smooth-scrollbar';

const MenuSidebar = ({toggleMenuSidebar}) => {

    useEffect(() => {
        Scrollbar.init(document.querySelector('#sidebar-list'));
    }, [])

    return <aside className="main-sidebar sidebar-light-gray">
        <Link to="/" className="brand-link text-center">
            <Image src={LogoMobile} alt="Hisably Logo" className="brand-image"/>
            <Image src={Logo} alt="Hisably reyre" className="brand-text font-weight-light"/>
        </Link>
        <div className="sidebar" id="sidebar-list">
            {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={ProfileImage}
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            User's name
                        </Link>
                    </div>
                </div> */}
            <SideBarList toggleMenuSidebar={toggleMenuSidebar}/>
        </div>
    </aside>;
};

export default MenuSidebar
