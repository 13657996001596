import axios from "axios";
import {getUserUrl} from "../api/auth/Auth";
// import NProgress from "nprogress/nprogress.js";

let API_BASE_URL = document.head.querySelector('meta[name="API_BASE_URL"]');
if (!API_BASE_URL) {
    console.error('Api Base URL not found');
}

// Create axios instance
const Request = axios.create({
    baseURL: API_BASE_URL.content + '/',
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});

Request.defaults.withCredentials = true;

// const calculatePercentage = (loaded, total) => (Math.floor(loaded * 1.0) / total);

// Request.defaults.onDownloadProgress = e => {
//     const percentage = calculatePercentage(e.loaded, e.total)
//     // NProgress.set(percentage)
// }

Request.interceptors.request.use(request => {
    // NProgress.start();
    if(request.url !== 'v1/lottery/scan' && request.url !== 'v1/lottery/add_book_scan' && request.url !== 'v1/lottery/verify_game'){
        document.body.classList.add('ajax-loader');
    }
    return request;
})

Request.interceptors.response.use(response => {
    // NProgress.done(true);
    document.body.classList.remove('ajax-loader');
    return response;
})

Request.interceptors.response.use((response) => response, (error) => {
    document.body.classList.remove('ajax-loader');
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401 && error.response.config.url !== getUserUrl)
        window.location.reload();
    if (statusCode === 419)
    {
        return Request.get('/sanctum/csrf-cookie').then( async () => {
            return Request.request(error.config);
        });
    }
});

export default Request;
