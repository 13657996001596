import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, Form, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import store from "../../store";
import {setNewToast} from "../../store/reducers/App";
import GlobalToast from "../../modules/Other/GlobalToast";
import {userForgotPassword} from "../../store/reducers/Auth";
import HisablyLogoWhite from "../../assets/images/logo-main.png";
// import MotionPage from "../../modules/custom/MotionPage";


const ForgotPassword = ({user}) => {

    // API process
    const [process, setProcess] = useState(false);

    // Inputs
    let emailInput = useRef('');

    useEffect(() => {
        if (user !== null && (user.stores instanceof Array && user.stores.length === 0)) {
            store.dispatch(setNewToast({
                title: 'Unauthorized Access!',
                body: 'No Stores Are Assigned To Your Account',
                variant: 'warning',
                autoHide: 5000
            }))
        }
    }, [user])

    useEffect(() => {
        setTimeout(() => emailInput?.focus(), 1000)
    }, [])

    const forgotPassword = async () => {
        setProcess(true);
        const result = await userForgotPassword(emailInput.value);

        if (result.status) {
            setProcess(false);
            store.dispatch(setNewToast({
                title: 'Success!',
                body: result.message,
                variant: 'success',
                autoHide: 5000
            }))

            window.location.reload();

        } else {
            store.dispatch(setNewToast({
                title: 'Warning...!',
                body: result.message,
                variant: 'warning',
                autoHide: 5000
            }))
            setProcess(false);
        }
    }

    return <div className="hold-transition login-page">
        <GlobalToast/>
        <div className="login-container">
            <div className="login-card">
                <div className="login-left-content">
                    <div className="login-top-content">
                        <h2 className="mb-3">Forgot Password</h2>
                        <p>If you can not remember your password. Reset your password to access Daily Reports, Invoices and Payments, Payroll and Lottery Inventory</p>
                    </div>
                    <div className="login-bottom-content">
                        <img src={HisablyLogoWhite}/>
                    </div>
                </div>
                <div className="login-right-content">
                    <div className="login-top-content">
                        <Form onSubmit={async e => {
                            e.preventDefault();
                            await forgotPassword()
                        }}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" ref={ref => {
                                    emailInput = ref
                                }} placeholder="Email"/>

                            </Form.Group>
                            <Button disabled={process} type="submit" variant="primary" block>
                                {
                                    !process && 'Forgot Password'
                                }
                                {
                                    process && <Spinner size="sm" animation="grow"/>
                                }
                            </Button>
                        </Form>
                        <Link to="/login" className="mt-3 text-center d-block"> Back to login </Link>
                    </div>
                    <div className="login-bottom-content">
                        <Link to="/register" className="mt-5 text-center d-block"> New to Hisably? Create an
                            account </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.Auth.user,
})

export default connect(mapStateToProps)(ForgotPassword)