import React from "react";
import {connect} from "react-redux";
import {Alert, Toast} from "react-bootstrap";
import "../../styles/GlobalToast.css"
import store from "../../store";
import {removeToast} from "../../store/reducers/App";

const GlobalToast = ({toasts}) => {

    const removeKeyToast = (key) => {
        store.dispatch(removeToast(key));
    }

    return <div className="background-container">
        {
            Object.keys(toasts).map((key) => (
                <Toast className="c-toast" key={key} onClose={() => removeKeyToast(key)} animation={true}
                       autohide={toasts[key].autoHide !== undefined} delay={toasts[key].autoHide !== undefined && toasts[key].autoHide}>
                    {
                        toasts[key].title !== undefined &&
                        <Toast.Header>
                            <strong className="mr-auto">{toasts[key].title}</strong>
                            {toasts[key].time !== undefined && <small>{toasts[key].time}</small>}
                        </Toast.Header>
                    }
                    {
                        toasts[key].body !== undefined &&
                        <Alert
                            className="m-0 rounded-0"
                            variant={toasts[key].variant !== undefined ? toasts[key].variant : 'light'}>
                            <Toast.Body className="p-0 m-0">{toasts[key].body}</Toast.Body>
                        </Alert>
                    }
                </Toast>
            ))
        }
    </div>
}

const mapStateToProps = (state) => ({
    toasts: state.App.toasts
})

export default connect(mapStateToProps)(GlobalToast)