import React from "react";
import NProgress from "nprogress/nprogress.js";
import MotionPage from "./MotionPage";

export default importComponent => {
    class AsyncComponent extends React.Component {
        static Component = null;
        state = { Component: AsyncComponent.Component };

        componentWillMount() {
            if (!this.state.Component) {
                // NProgress.start();
                importComponent().then(module => module.default).then(Component => {
                    AsyncComponent.Component = Component
                    this.setState({Component});
                    // NProgress.done(true);
                })
            }
        }
        render() {
            const { Component } = this.state
            if (Component) {
                return <MotionPage>
                    <Component {...this.props} />
                </MotionPage>
            }
            return <div></div>
        }
    }
    return AsyncComponent;
}
