import {combineReducers, createStore} from 'redux'
import Auth from "./reducers/Auth";
import App from "./reducers/App";
import StoreSettings from "./reducers/StoreSettings";

const Reducers = combineReducers({
    Auth,
    App,
    StoreSettings
})

const Store = createStore(Reducers)

export default Store;
