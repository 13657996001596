import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Table from 'react-bootstrap/Table';
import Request from "../../utils/Request";
import {api_version} from "../../settings";
import {formatDate, responseValidate} from "../../utils/functions";

const Refer = ({store}) => {

    const [firstRender, setFirstRender] = useState(true);
    const [referral, setReferral]       = useState([]);

    const fetchReferal = () => {
        Request.get(`${api_version}/core/referal`, {
            params: {
                store_id: store?.id
            }})
			.then(responseValidate).then(result => {
			if (result.status) {
                setReferral(result.data);
			}
        })
    }

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            fetchReferal();
        }
    });

	return <>
		<div className="container">
            <div className="row mb-3 pb-5 pb-md-0">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="white-card refer_banner_section">
                        <h3>
                            <strong>REFERRAL BONUS</strong>
                        </h3>
                        <h2>Wanna make Hisably FREE?</h2>
                        <p>&nbsp;</p>
                        <p>Refer Hisably to your friend and once the referred store gets paid account,<br /> earn yourself a FREE month and repeat the same to get FREE months.</p>
                    </div>
                </div>
            </div>
            <div className="row mb-3 pb-5 pb-md-0">
                <div className="main_section col-12 col-xs-12 col-md-4 col-lg-4">
                    <div className="white-card free-sidebar">
                        <h3>
                            <strong>How it Works</strong>
                        </h3>
                        <div className="steps">
                            <div className="main_section_icon">
                                <span className="main_section_span"><i className="main_section_i fas fa-envelope" aria-hidden="true"></i></span>
                            </div>
                            <div className="content">
                                <h4>1. Invite your friends</h4>
                                <p>Invite your friends to register on <a target="_blank" href="https://hisably.com">Hisably</a>.</p>
                            </div>
                        </div>
                        <div className="steps">
                            <div className="main_section_icon">
                                <span className="main_section_span"><i className="fas fa-volume-up" aria-hidden="true"></i></span>
                            </div>
                            <div className="content">
                                <h4>2. Promote Hisably</h4>
                                <p>Tell your friends to use your store name while registering for Hisably.</p>
                            </div>
                        </div>
                        <div className="steps">
			                <div className="main_section_icon">
			                    <span className="main_section_span"><i className="fas fa-money-bill" aria-hidden="true"></i></span>
			                </div>
			                <div className="content">
			                    <h4>3. Get FREE Month</h4>
			                    <p>Sit back, once your friends gets paid account, you get 1 month FREE subscription. Rinse &amp; repeat to make more months FREE.</p>
			                </div>
			            </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-md-8 col-lg-8">
                    <div className="white-card">
                        <div className="data-table-content pb-5 pb-md-0">
                            <h3>Referal History</h3>
                            <Table responsive id="example" className="data-table">
                                <thead>
                                    <tr>
                                        <th>Referred Store</th>
                                        <th>Joined Date</th>
                                        <th>Contact Person</th>
                                        <th>Free Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        referral.length > 0 
                                        ?
                                            <>
                                                {
                                                    referral.map((row, index) => {
                                                        return <tr key={index}>
                                                            <td>{row?.referral_store?.name}</td>
                                                            <td>{formatDate(row?.referral_store?.created_at)}</td>
                                                            <td>{row?.contact_person}</td>
                                                            <td>{formatDate(row?.free_month_to)}</td>
                                                        </tr>
                                                    })
                                                }
                                            </>
                                        :
                                        <tr>
                                            <td colSpan="4" align="center">You have not referred anyone, start referring now and make your Hisably account FREE!</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</>
}

const mapStateToProps = (state) => ({
	store: state.App.store,
})

export default connect(mapStateToProps)(Refer)
