import React, {useState} from "react";
import routes from "../../../routing/routes";
import {SideBarItem} from "./SideBarItem";
import SideBarDropDown from "./SideBarDropDown";
import {connect} from "react-redux";


const nonHiddenRoutesLength = (routes, userRoles) => routes.filter(row => (userRoles?.[0] === 'Super Admin' && row.super_admin_show || !row.hidden)).length

function SidebarList({toggleMenuSidebar, settings, user, userRoles}) {
    const [selected, setSelected] = useState(0);
    return <nav>
        <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-flat"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            {routes.map(function (value, index) {
                if (value.settings_key !== undefined) {
                    if (settings?.[value.settings_key].value != '0') {
                        if (value.child === undefined)
                            return <SideBarItem user={user} key={index} base="" {...value} toggleMenuSidebar={toggleMenuSidebar}
                                                selected={selected} setSelected={setSelected}/>
                        else {
                            if (nonHiddenRoutesLength(value.child, userRoles) === 1)
                                return <SideBarItem user={user} key={index} {...value.child[0]}
                                                    base={value.path} name={value.name}
                                                    toggleMenuSidebar={toggleMenuSidebar}
                                                    selected={selected}
                                                    setSelected={setSelected}/>
                            else
                                return <SideBarDropDown key={index} {...value} toggleMenuSidebar={toggleMenuSidebar}
                                                        selected={selected} setSelected={setSelected}/>
                        }
                    }
                    return <div key={index}/>
                } else {
                    if (value.child === undefined)
                        return <SideBarItem user={user} key={index} base="" {...value} toggleMenuSidebar={toggleMenuSidebar}
                                            selected={selected} setSelected={setSelected}/>
                    else {
                        if (nonHiddenRoutesLength(value.child, userRoles) === 1)
                            return <SideBarItem user={user} key={index} {...value.child[0]}
                                                base={value.path} name={value.name}
                                                toggleMenuSidebar={toggleMenuSidebar}
                                                selected={selected}
                                                setSelected={setSelected}/>
                        else
                            return <SideBarDropDown key={index} {...value} toggleMenuSidebar={toggleMenuSidebar}
                                                    selected={selected} setSelected={setSelected}/>
                    }
                }
            })}
        </ul>
    </nav>
}

const mapStateToProps = state => ({
    settings: state.StoreSettings.settings,
    user: state.Auth.user,
    userRoles: state.Auth.user.roles,
})

export default connect(mapStateToProps)(SidebarList)