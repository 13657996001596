import Request from "./Request";
import {api_version} from "../settings";

export class ApiClient {
    static resourceName = "";

    static index(data) {
        return Request.get(`${api_version}/${this.resourceName}`, {
            params: data
        });
    }

    static store(data) {
        return Request.post(`${api_version}/${this.resourceName}`, data);
    }

    static show(id) {
        return Request.post(`${api_version}/${this.resourceName}/${id}`);
    }

    static update(id, data) {
        return Request.put(`${api_version}/${this.resourceName}/${id}`, data);
    }

    static destroy(id) {
        return Request.delete(`${api_version}/${this.resourceName}/${id}`);
    }
}