import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {api_version} from "../../settings";
import Store from "../../store/index";
import {responseValidate} from "../../utils/functions";
import Request from "../../utils/Request";
import {setNewToast} from "../../store/reducers/App";

function Print() {

    const [firstRender, setFirstRender] = useState(true);
    const [html, setHtml]               = useState('');
    const [loading, setLoading]         = useState(false);

    useEffect(() => {
        if(firstRender){
            setFirstRender(false);
            printData();
        }
    });

    const printData = () => {
        let url         = new URL(window.location.href);
        let store_id    = url.searchParams.get("store_id");
        let shift_id    = url.searchParams.get("shift_id");
        let date        = url.searchParams.get("date");
        setLoading(true);
        Request.post(`${api_version}/daily_report/reports/shift/print`, {
            store_id: store_id,
            shift_id: shift_id,
            date: date,
        }).then(responseValidate).then(result => {
            setLoading(false);
            if (result.status) {
                setHtml(result.data.html);
            } else {
                Store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'danger'
                }));
            }
        })
    }

    return <>
        {
            html != ''
            ?
                <>{ <div dangerouslySetInnerHTML={{ __html: html }} /> }</>
            :
                <>
                    {
                        loading === true
                        ? 
                            <div style={{marginTop: "10px", width: "100%", textAlign: "center"}}>
                                <h3>Loading...</h3>
                            </div>
                        : 
                            <div style={{marginTop: "10px", width: "100%", textAlign: "center"}}>
                                <h3>Sorry No Records Found</h3>
                            </div>
                    }
                </>
        }        
    </>
}

export default Print