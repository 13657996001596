const settings = {
    api_version: 'v1',
    app_timezone: 'America/New_York',
    super_admin: 'Admin',
    vendorTypes: [
        {value: '1', label: 'Purchase'},
        {value: '2', label: 'Expense'},
        {value: '3', label: 'Others'},
        {value: '4', label: 'Payroll'},
    ],
    permissionTypes: {
        readAll: {
            key: 'read-all',
            background: 'gray',
            color: 'black'
        },
        readOne: {
            key: 'read-one',
            background: 'gray',
            color: 'black'
        },
        create: {
            key: 'create',
            background: 'green',
            color: 'black'
        },
        update: {
            key: 'update',
            background: 'yellow',
            color: 'black'
        },
        delete: {
            key: 'delete',
            background: 'red',
            color: 'black'
        },
        custom: {
            key: 'custom',
            background: 'darkgray',
            color: 'white'
        },
    },
    transactionTypes: [
        {
            key: 'Transaction\\Models\\StoreCashTransaction',
            value: 'Cash'
        },
        {
            key: 'Transaction\\Models\\StoreBankTransaction',
            value: 'Bank'
        },
        {
            key: 'Transaction\\Models\\StoreChequeTransaction',
            value: 'Check'
        },
    ],
    payrollTypes: [
        {
            id: 1,
            name: 'Hourly',
        },
        {
            id: 2,
            name: 'Weekly',
        },
        {
            id: 3,
            name: 'Bi Weekly',
        },
        {
            id: 4,
            name: 'Monthly',
        },
    ]
}

export const {
    api_version,
    super_admin,
    vendorTypes,
    permissionTypes,
    transactionTypes,
    app_timezone,
    payrollTypes,
} = settings
