import React from 'react'
import PermissionCheck from "../../modules/Auth/PermissionCheck";
import Nav from 'react-bootstrap/Nav';
import {
    business_reports,
    gas_invoice,
    gas_reports,
    ledgers,
    lottery_reports,
    lottery_routes,
    top_business_reports,
    settings
} from "../../routing/routes/index";
import {NavLink} from "react-router-dom";

const TopNav = ({navMenu}) => {

    let routes;
    if (navMenu == 'lottery_routes') {
        routes = lottery_routes;
    } else if (navMenu == 'gas_invoice') {
        routes = gas_invoice;
    } else if (navMenu == 'gas_reports') {
        routes = gas_reports;
    } else if (navMenu == 'ledgers') {
        routes = ledgers;
    } else if (navMenu == 'business_reports') {
        // routes = business_reports;
        routes = top_business_reports;
    } else if (navMenu == 'lottery_reports') {
        routes = lottery_reports;
    } else if (navMenu == 'settings') {
        routes = settings;
    } else {
        return <></>
    }

    return (
        <div className="top-inner-nav mb-3">
            <Nav as="ul">
                {
                    routes.map(function (route, key) {
                        if (route.top_hidden) {
                            return <div key={key} />
                        }

                        return <PermissionCheck key={key} permissions={route?.permissions}>
                            <Nav.Item as="li" key={key+route?.id}>
                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to={route?.parent_path+route?.path} exact> {route?.name} </NavLink>
                            </Nav.Item>
                        </PermissionCheck>
                    })
                }
            </Nav>
        </div>
    )
}

export default TopNav