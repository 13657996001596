import React from "react";
import routes from "./routes";
import PrivateRoute from "./PrivateRoute";
import {connect} from "react-redux";
import {isSubscribed} from "../utils/subscription";

const PrivateRouter = ({store}) => {

    return isSubscribed(store) && routes.map(function (value, index) {
        if (value.child !== undefined)
            return <div key={index}>
                {value.child.map(function (child, child_id) {
                    return <PrivateRoute
                        exact
                        key={child_id}
                        path={value.path + child.path}
                        component={child.component}
                        title={child.title}
                        name={child.name}
                        permissions={child.permissions}
                        settings_key={child.settings_key}
                    />
                })}
            </div>
        else
            return <PrivateRoute
                exact
                key={index}
                path={value.path}
                component={value.component}
                title={value.title}
                name={value.name}
                permissions={value.permissions}
                settings_key={value.settings_key}
            />
    });
};

const mapStateToProps = state => ({
    store: state.App.store,
})

export default connect(mapStateToProps)(PrivateRouter)
