import React, {useEffect, useState} from "react";
import {hasAnyChildPermission} from "../../../utils/functions";
import {SideBarItem} from "./SideBarItem";
import {connect} from "react-redux";

const nonHiddenRoutes = (routes, userRoles) => routes.filter(row => (userRoles?.[0] === 'Super Admin' && row.super_admin_show || !row.hidden))

function SideBarDropDown({userPermissions, userRoles, child, path, name, base, icon, toggleMenuSidebar, settings,user}) {
    const [showDropdown, setShowDropdown]   = useState(false);
    const [show, setShow]                   = useState(false);
    const [firstRender, setFirstRender]     = useState(true);
    const [navCls, setNavCls]               = useState('');

    //store acess profit and loss id 
    const enabledAccessStores = [1];
    
    useEffect(() => {
        const status = hasAnyChildPermission(nonHiddenRoutes(child, userRoles), userPermissions, userRoles);
        setShowDropdown(status)
    }, [userPermissions, child]);

    useEffect(() => {
        if (firstRender){
            setFirstRender(false);
            setNavCls(name == "Daily Report" ? 'menu-open' : show ? 'menu-open': '');
            setShow(name == "Daily Report" ? true : false);
        }
    }, []);

    const toggleMenu = (e) => {
        e.preventDefault();
        if (show) {
            setShow(false);
            setNavCls('');
        } else {
            setShow(true);
            setNavCls('menu-open');
        }
    }

    // let navMenuClass = name == "Daily Report" ? 'menu-open' : show ? 'menu-open': '';
    // let navMenuClass = show ? 'menu-open': '';

    return <>
        {
            showDropdown && <>
                <li className={'nav-item ' + navCls}>
                    <a href="#" onClick={toggleMenu}
                       className="nav-link">
                        <i className={'nav-icon fas ' + icon}/>
                        <p>
                            {name}
                            <i className="right fas fa-angle-right"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        {child.map(function (child, index) {
                            if (child.name === 'Profit & Loss Report' && !enabledAccessStores.includes(user.store_id)) {
                                return null;
                            }
                            if (child.settings_key !== undefined) {
                                if (settings?.[child.settings_key].value != '0')
                                    return <SideBarItem key={index} {...child} base={path}
                                                        toggleMenuSidebar={toggleMenuSidebar}/>
                                else
                                    return <div key={index}/>
                            } else {
                                return <SideBarItem key={index} {...child} base={path}
                                                    toggleMenuSidebar={toggleMenuSidebar}/>
                            }
                        })}
                    </ul>
                </li>
            </>
        }
    </>
}


// function MyComponent (props) {
//   const [isActive, setActive] = useState(false);

//   const toggleClass = () => {
//     setActive(!isActive);
//   };

//   return (
//     <div 
//       className={isActive ? 'menu-open': null} 
//       onClick={toggleClass} 
//     >
//       <p>{props.text}</p>
//     </div>
//    );
// }  


const mapStateToProps = (state) => ({
    userPermissions: state.Auth.user.permissions,
    userRoles: state.Auth.user.roles,
    settings: state.App.store.settings,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(SideBarDropDown)