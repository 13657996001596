import React, { useEffect } from "react";
import {NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PermissionCheck from "../../Auth/PermissionCheck";
import {isMobileOrTablet} from "../../../utils/functions";

export function SideBarItem({name, base, permissions, path, icon, toggleMenuSidebar, hidden, super_admin_show}) {
    const closeSideBar = () => {
        if (isMobileOrTablet())
            toggleMenuSidebar()
    }

    const { pathname } = useLocation();

    if(super_admin_show) {
    } else {
        if(hidden)
            return <></>
    }
    
    return <PermissionCheck permissions={permissions}>
        <li className="nav-item">
            {
                base !== '' && base !== '/report' && base !== '/reports' && !pathname.includes('/reports/') && !pathname.includes('/report/') ?
                <NavLink 
                    to={base + path}
                    exact
                    className="nav-link"
                    onClick={closeSideBar}
                    isActive={() => pathname.includes(base)}
                    >
                    <i className={'nav-icon fas ' + icon}/>
                    <p>{name}</p>
                </NavLink>
                :
                <NavLink 
                    to={base + path}
                    exact
                    className="nav-link"
                    onClick={closeSideBar}
                
                    >
                    <i className={'nav-icon fas ' + icon}/>
                    <p>{name}</p>
                </NavLink>
            }
        </li>
    </PermissionCheck>
}