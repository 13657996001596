import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";
import {check} from "../utils/functions";
import {super_admin} from "../settings";
import NoPermission from "../pages/auth/NoPermission";

function Check({children, permissions, userPermissions, userRoles}) {
    const [hasRole, setHasRole] = useState(check([super_admin], userRoles))
    const [hasPermissions, setHasPermissions] = useState(hasRole ? true : (permissions === undefined ? true : check(permissions, userPermissions)))

    useEffect(() => {
        setHasRole(check([super_admin], userRoles));
    }, [userRoles])

    useEffect(() => {
        setHasPermissions(hasRole ? true : (permissions === undefined ? true : check(permissions, userPermissions)))
    }, [hasRole, permissions, userPermissions, userRoles])

    return hasPermissions ? children : <NoPermission />
}

/* Private Route */
const PrivateRoute = ({
                          component: Component,
                          layout: Layout,
                          permissions: Permissions,
                          userPermissions,
                          userRoles,
                          settings_key,
                          settings,
                          ...rest
                      }) => {

    if (settings_key != undefined)
        if (settings?.[settings_key].value == '0')
            return <div/>

    return <Route
        {...rest}
        render={props =>
            rest.isLoggedIn ? <Check
                permissions={Permissions}
                userPermissions={userPermissions}
                userRoles={userRoles}>
                <Component {...props} {...rest} />
            </Check> : <Redirect
                to={{pathname: "/login", state: {from: props.location}}}
            />
        }
    />
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
    userPermissions: state.Auth.user.permissions,
    userRoles: state.Auth.user.roles,
    settings: state.StoreSettings.settings,
})

export default connect(mapStateToProps)(PrivateRoute)
