const statuses = {
    trialing: 'trialing',
    active: 'active',
    incomplete: 'incomplete',
    incomplete_expired: 'incomplete_expired',
    past_due: 'past_due',
    canceled: 'canceled',
    unpaid: 'unpaid',
};

export const {trialing, active, incomplete, incomplete_expired, past_due, canceled, unpaid} = statuses;

export const isTrialing = status => status === trialing;

export const isActive = status => status === active;

export const isInComplete = status => status === incomplete;

export const isInCompleteExpired = status => status === incomplete_expired;

export const isPastDue = status => status === past_due;

export const isCanceled = status => status === canceled;

export const isUnpaid = status => status === unpaid;

export const isSubscribed = store =>
    isActive(store?.store_subscription?.stripe_status) || isTrialing(store?.store_subscription?.stripe_status)
