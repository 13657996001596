
const SettingState = {
    settings: null
};

export const setStoreSettings = (Object) => {
    return {
        type: "Store/Settings",
        val: Object
    }
}

export default function StoreSettings(state = SettingState, action) {
    switch (action.type) {
        case setStoreSettings().type:
            return {...state, settings: action.val};
        default:
            return state;
    }
}