import React, {useEffect, useState} from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import FinishShift from "./daily_report/finish-shift";
import {useLocation} from "react-router-dom";

export const DarkMode = 'skin-midnight';

function getDrawerPosition() {
    const position = localStorage.getItem("drawerPosition");
    return position !== null ? (position === "true") : false;
}

function setDrawerPosition(position) {
    localStorage.setItem("drawerPosition", position)
}

export function getTheme() {
    const themeClass = localStorage.getItem("appTheme");
    return themeClass !== null ? themeClass : '';
}

export function setTheme(themeClass) {
    localStorage.setItem("appTheme", themeClass)
}

const Main = ({children}) => {

    const location = useLocation();

    const [themeMode, setThemeMode] = useState(getTheme());

    const [appLoadingState, updateAppLoading] = useState(true);
    const [menuSidebarState, updateMenuSidebarState] = useState({
        isMenuSidebarCollapsed: getDrawerPosition()
    });

    const toggleMenuSidebar = () => {
        const position = !menuSidebarState.isMenuSidebarCollapsed;
        setDrawerPosition(position)
        updateMenuSidebarState({
            isMenuSidebarCollapsed: position
        });
    };

    const changeTheme = () => {
        const mode = themeMode === DarkMode ? '' : DarkMode;
        setTheme(mode);
        setThemeMode(mode);
    };

    useEffect(() => {
        setTimeout(() => updateAppLoading(false), 2000)
    }, [])

    const eventListener = (e) => {
        if (e.key === '1' && (e.altKey || e.metaKey)) {
            e.preventDefault();
            toggleMenuSidebar();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', eventListener);
        return () => {
            window.removeEventListener('keydown', eventListener);
        };
    }, []);

    useEffect(() => {
        document.getElementById('root').classList.remove('register-page');
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');
        if (themeMode !== DarkMode)
            document.getElementById('root').classList.remove(DarkMode);
        document.getElementById('root').className += ' sidebar-mini text-sm layout-fixed layout-footer-fixed ' + themeMode;
    }, [themeMode])

    if (menuSidebarState.isMenuSidebarCollapsed) {
        document.getElementById('root').classList.add('sidebar-collapse');
        document.getElementById('root').classList.remove('sidebar-open');
    } else {
        document.getElementById('root').classList.add('sidebar-open');
        document.getElementById('root').classList.remove('sidebar-collapse');
    }

    const currentURL = window.location.href;
    const segment    = currentURL.split("/");

    const getNavRouteParam = (param) => {
        let res = '';
        if (param == 'lottery') {
            res = 'lottery_routes';
        } else if (param == 'ledger') {
            res = 'ledgers';
        } else if (param == 'gas') {
            res = 'gas_invoice';
        } else if (param == 'reports') {
            let last_segment = segment[segment.length - 1];
            if (last_segment == 'business-analytics' || last_segment == 'business-monthly' || last_segment == 'purchase-monthly' || last_segment == 'expense-monthly' || last_segment == 'payroll-monthly') {
                res = 'business_reports';
            } else if (last_segment == 'lottery-analytics' || last_segment == 'lottery-monthly') {
                res = 'lottery_reports';
            } else if (last_segment == 'gas-report' || last_segment == 'gas-analytics' || last_segment == 'gas-difference' || last_segment == 'gas-commissions') {
                res = 'gas_reports';
            }
        } else if (param == 'settings') {
            res = 'settings';
        }
        return res;
    }

    return <div className="wrapper">
        <>
            <Header toggleMenuSidebar={toggleMenuSidebar} themeMode={themeMode} changeTheme={changeTheme} segment={getNavRouteParam(segment[segment.length - 2])} />
            <MenuSidebar toggleMenuSidebar={toggleMenuSidebar}/>
            <div className={getNavRouteParam(segment[segment.length - 2]) == '' ? 'content-wrapper pt-3' : 'content-wrapper pt-3 doubble-topbar'} onClick={() => !menuSidebarState.isMenuSidebarCollapsed ? updateMenuSidebarState({isMenuSidebarCollapsed: true}) : '' }>
                <section className="content">
                    {children}
                </section>
            </div>
            <Footer/>
            <div role="presentation"
                 onClick={toggleMenuSidebar}
                 onKeyDown={() => {}}
            />
            {/* <div id="sidebar-overlay" onClick={toggleMenuSidebar} />
            {
                location.pathname === '/report/daily_report' && <FinishShift/>
            } */}
        </>
    </div>;
};

export default Main
