import React, {useState} from 'react';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Col from "react-bootstrap/Col";
import {Button, Form} from "react-bootstrap";
import Store from '../../store'
import {setNewToast} from "../../store/reducers/App";
import PaymentMethod from "../../api/settings/PaymentMethod";
import {responseValidate} from "../../utils/functions";
import {connect} from "react-redux";

const Wrapper = ({onSaved = () => {}, store_id}) => {
return <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY)}>
    <SaveCard onSaved={onSaved} store_id={store_id} />
</Elements>
}

const SaveCard = ({onSaved = () => {}, store_id}) => {

    const [holderName, setHolderName]   = useState('');
    const [loading, setLoading]         = useState(false);
    const elements = useElements();
    const stripe = useStripe();

    const onSubmit = async e => {
        e.preventDefault();
        const card = elements.getElement(CardElement);

        if (!holderName) {
            Store.dispatch(setNewToast({
                body: 'Please enter card holder name.',
                variant: 'danger'
            }))
        }

        setLoading(true);

        const {paymentMethod, error} = await stripe.createPaymentMethod({
                type: 'card',
                card: card,
                billing_details: {
                    name: holderName,
                },
            }
        );

        if (error) {
            Store.dispatch(setNewToast({
                body: error.message,
                variant: 'danger'
            }))
        } else {
            PaymentMethod.store({
                payment_method_id: paymentMethod.id,
                store_id: store_id
            }).then(responseValidate).then(result => {
                if (result.status) {
                    Store.dispatch(setNewToast({
                        body: result.message,
                        variant: 'success'
                    }))
                    onSaved();
                } else {
                    Store.dispatch(setNewToast({
                        body: result.message,
                        variant: 'danger'
                    }))
                }
            })
        }

        setLoading(false);
    }

    return <form onSubmit={onSubmit}>
        <Form.Row>
            <Form.Group as={Col} sm={12}>
                <Form.Label>Card Holder Name <span>*</span></Form.Label>
                <Form.Control type="text" placeholder="Full name"
                              value={holderName} onChange={e => setHolderName(e.target.value)}/>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} sm={12}>
                <div className="form-control py-2">
                    <CardElement />
                </div>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group as={Col} sm={12}>
                <Button type="submit" block>
                    {
                        loading && <i className="fa fa-spinner fa-spin"></i> 
                    }
                    &nbsp;Save Card
                </Button>
            </Form.Group>
        </Form.Row>

    </form>;
};

const mapStateToProps = state => ({
    store_id: state.App.store?.id,
})

export default connect(mapStateToProps)(Wrapper);