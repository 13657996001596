import Request from "../../utils/Request";
import {api_version} from "../../settings";

export const getStoreShift = async (store_id, date, shift, page = '') => Request.get(`${api_version}/core/stores/shift`, {
    params: {
        store_id,
        date,
        shift,
        page,
    }
});

export const getStoreShifts = async (store_id, date, shift) => Request.get(`${api_version}/core/stores/shifts`, {
    params: {
        store_id,
        date,
        shift
    }
});

export const createStoreShift = async (store_id, date) => Request.put(`${api_version}/core/stores/shift`, {
    store_id,
    date,
});

export const deleteStoreShift = async (shift_id) => Request.delete(`${api_version}/core/stores/shift/${shift_id}`);
