import React, {useEffect, useState} from "react";
import store from "../../store";
import {setNewToast, setPageName} from "../../store/reducers/App";
import {Button, Form, Modal, Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";
import HisablyLogoWhite from "../../assets/images/logo-main.png";
import GlobalToast from "../../modules/Other/GlobalToast";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Request from "../../utils/Request";
import {api_version} from "../../settings";
import {responseValidate} from "../../utils/functions";
import {Redirect} from "react-router-dom";

export default function Register({title}) {

    const [tab, setTab] = useState('step_1');
    const [loading, setLoading] = useState(false);
    const [isZipAvailable, setIsZipAvailable] = useState(false);
    const [showNotAvailable, setShowNotAvailable] = useState(false);
    // const [message, setMessage] = useState('');
    // Step 1
    const [phone_number, setPhoneNumber] = useState('');
    const [email_address, setEmailAddress] = useState('');
    const [address, setAddress] = useState();
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    // Step 2
    const [OTP, setOTP] = useState('');
    // Step 3
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isRedirect, setIsRedirect]   = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [additionalField, setAdditionalField] = useState('');

    const [changeApiTimer, setChangeApiTimer] = useState(0);
    const changeWait = (callBack) => {
        if (changeApiTimer)
            clearTimeout(changeApiTimer);
        setChangeApiTimer(setTimeout(callBack, 1000));
    }

    useEffect(() => {
        if (title !== undefined)
            document.title = title;
        store.dispatch(setPageName(title));
    });
    const options = [
        { value: 'Facebook Ads', label: 'Facebook Ads' },
        { value: 'Google Search', label: 'Google Search' },
        { value: 'Received PostCard', label: 'Received PostCard' },
        { value: 'Referred By Friend', label: 'Referred By Friend' },
        { value: 'I already have store in Hisably', label: 'I already have store in Hisably' }
    ];
    const pinCodeSearch = (inputValue, callback) => {
        if (inputValue) {
            changeWait(() => Request.get(`api/${api_version}/addresses/pin_code/search`, {
                params: {
                    q: inputValue
                }
            }).then(responseValidate).then(result => {
                if (result.status) {
                    if (result.data.length > 0)
                    {
                        let new_options = [];
                        result.data.map(row => {
                            new_options.push({
                                ...row,
                                label: row.aria_name + ' (' + row.code + ')',
                                value: row.id
                            })
                        })
                        callback(new_options);
                    }
                }
            }))
        } else callback([]);
    };

    const addressInputChange = (address) => {
        setCountry(address?.country_name ?? '');
        setState(address?.state_name ?? '');
        setAddress(address);
    };
    const existingEmails = [
        "sumeet@unlimitedwp.com",
        "info@jdsofttech.com",
        "demo@muckeys.com",
        "arck100@yahoo.com",
        "ANNA@GM.COM",
        "info@thuisradiofm.eu",
        "HEM.VORA@GMAIL.COM",
        "myat66main@gmail.com",
        "kkpatel@bu.edu",
        "vipul@redapple.com",
        "pateljignesh0088@gmail.com",
        "SandraDonovan30@gmail.com",
        "test@walla.com",
        "bsingh1@gmail.com",
        "solaress1980@gmail.com",
        "trmrbir-5@yahoo.com",
        "hank@mardukas.net",
        "taxpayer.johnq@gmail.com",
        "khunitcomputer@gmail.com",
        "lidale0723@hotmail.com",
        "demo@jigar.com",
        "subashbansal2000@yahoo.com",
        "nmhfuentesmarket@gmail.com",
        "jigar_patel1@student.uml.edu",
        "whatsmyname007@gmail.com",
        "demo10@demo.com",
        "helalami.slitane85@gmail.com",
        "nileshhisably@gmail.com",
        "jigar@hisably.com",
        "galarconsantillan@berklee.edu",
        "moss@bella.com",
        "joel@bella.com",
        "khush888@gmail.com",
        "kokel@yk20.com",
        "m_donepudi@yahoo.com",
        "kikee019@yahoo.com",
        "simons@convenience.com",
        "abbasabdul699@gmail.com",
        "parthsammy@yahoo.com",
        "parthsammy@gmail.com",
        "andy.mobilmart@gmail.com",
        "sunnypatel8308@gmail.com",
        "threestaroil@hotmail.com",
        "amansama83@gmail.com",
        "hasingh23@yahoo.com",
        "Auston6573@gmail.com",
        "floridademo@hisably.com",
        "vishal@jdsofttech.com",
        "highlandfarms218@gmail.com",
        "uxbridgefoodmart@gmail.com",
        "tapanpatel917@gmail.com",
        "kitzinvest@gmail.com",
        "ravipatel434@yahoo.com",
        "amacnaughton2020@gmail.com",
        "springdale02021@gmail.com",
        "hitumeladi@gmail.com",
        "soliz_k@yahoo.com",
        "MINESHJDESAI@YAHOO.CO.UK",
        "rupalpatel1@gmail.com",
        "toli.gajjar@gmail.com",
        "krunal.gajjar03@gmail.com",
        "vijaygajjar2018@gmail.com",
        "nihar17999@gmail.com",
        "SK12S43h78@gmail.com",
        "bhadreshpatel1974@yahoo.com",
        "IRFAN@GMAIL.COM",
        "hanish83@gmail.com",
        "newhampshiredemo@hisably.com",
        "aj.captainscorner@gmail.com",
        "John1@abiretails.com",
        "bhumipatel1894@gmail.com",
        "sdpatel.2110@gmail.com",
        "winterhavenchevron@gmail.com",
        "mc459829@gmail.com",
        "newyorkstore@hisably.com",
        "darshp733@gmail.com",
        "erik.joshi85@gmail.com",
        "harrypatel94@yahoo.com",
        "howereg1shift1@gmail.com",
        "howereg1shift2@gmail.com",
        "howereg2shift2@gmail.com",
        "howereg1open@gmail.com",
        "noemail2@gmail.com",
        "rsg4446861@gmail.com",
        "chaitanya449@gmail.com",
        "howereg1shift4@gmail.com",
        "Sunshinelotto535@gmail.com",
        "Jaypatel4797@gmail.com",
        "jaypatel002@gmail.com",
        "harrypatel1902@outlook.com",
        "nsp@gmail.com",
        "maria2@bellas.com",
        "777luckycorner.mall@gmail.com",
        "luckymart276@gmail.com",
        "maria3@bellas.com",
        "eleshawork36@gmail.com",
        "gisell@bellas.com",
        "dh38patel@gmail.com",
        "MontuPatel1281@gmail.com",
        "spsoni1@gmail.com",
        "ajay.freedomdiscount@gmail.com",
        "ajay.ossipee@gmail.com",
        "abcd@gmail.com",
        "alayaero123@gmail.com",
        "juanpollo472@gmail.com",
        "shivpatel10@gmail.com",
        "guerreroluis02130@gmail.com",
        "maxskills3@gmail.com",
        "b_4desi@yahoo.com",
        "tinu381@yahoo.com",
        "marlboromarket135@gmail.com",
        "Samir.Soni1125@gmail.com",
        "dhiren43@gmail.com",
        "farhan_2266@yahoo.com",
        "plamoly@hotmail.com",
        "KarPat007@gmail.com",
        "pennsylvaniastore@hisably.com",
        "ad.parth7@gmail.com",
        "fantusurafel@gmail.com",
        "kiley@petrolafuel.com",
        "varelis617@gmail.com",
        "cashier@petrolafuel.com",
        "razahnd@hotmail.com",
        "Etabez@petrolafuel.com",
        "radiant1223@gmail.com",
        "Meherabdul@yahoo.com",
        "mit7897@gmail.com",
        "Qamarabbas806@gmail.com",
        "ashokpatel_usa@yahoo.com",
        "kydemostore@hisably.com",
        "ronik@yahoo.com",
        "prabgill154@gmail.com",
        "Lucky.Singh02141978@gmail.com",
        "krunalpatel122@gmail.com",
        "sobalbhatti2010@gmail.com",
        "mukeshp17@yahoo.com",
        "margaret1001@gmail.com",
        "raahi2616@yahoo.com",
        "rajpatelahir76@gmail.com",
        "regalfoodmart1@gmail.com",
        "kipra_trivedi@yahoo.com",
        "mayapatel1126@gmail.com",
        "Bhadresh@1666.com",
        "yogipatel13@comcast.net",
        "infinitysmokeshop@gmail.com",
        "Barotalpeshj@gmail.com",
        "patelvijay0066@gmail.com",
        "PRAVI4155@GMAIL.COM",
        "OALSAIDI@LMIFAY.COM",
        "pateljames_2007@yahoo.com",
        "mnzmartinc@gmail.com",
        "aftabzeb39@gmail.com",
        "raj631482@gmail.com",
        "patelnikhil1223@gmail.com",
        "ashokpatelusa@gmail.com",
        "saiconv2017@gmail.com",
        "djlavpatel@gmail.com",
        "northshorenewstand@gmail.com",
        "macnaughtonr@yahoo.com",
        "alejandracastillo723@gmail.com",
        "morev56@yahoo.com",
        "dmexxon5300@gmail.com",
        "Jevangeorges21@gmail.com",
        "Levineshallmark@gmail.com",
        "gasworld01@gmail.com",
        "notilasaws@gmail.com",
        "npatel851@gmail.com",
        "OBPCHAIN@GMAIL.COM",
        "route28llc@gmail.com",
        "tobaccojunction3300@gmail.com",
        "prabeshbuzz01@gmail.com",
        "rnpatel1987@gmail.com",
        "statelineliquors@gmail.com",
        "stevepatel2009@gmail.com",
        "krishna.bunit@gmail.com",
        "patelronak091@gmail.com",
        "167corpinc@gmail.com",
        "yarmouth1122@yahoo.com",
        "dhaval1122@yahoo.com",
        "Shahilp36@gmail.com",
        "basemmegelly@gmail.com",
        "davepatel52@gmail.com",
        "hys-inc@hotmail.com",
        "gasdepot11@gmail.com",
        "dhaval_1122@yahoo.com",
        "Harbor.xpress@hotmail.com",
        "Hitesh@richdale.com",
        "parikhmihirs@gmail.com",
        "heathernunya1982@icloud.com",
        "heathernunya1982@gmail.com",
        "sterling.gallegos@gmail.com",
        "Razahnd@gmail.com",
        "hisablyonestop@gmai.com",
        "shift2@onestop.com",
        "montupatidar99@icloud.com",
        "ronakp900@gmail.com",
        "sargodhia1@gmail.com",
        "deeprpatel1@gmail.com",
        "choudhrynaghman@yahoo.com",
        "fanig76@gmail.com",
        "jpskang18@gmail.com",
        "central@gmail.com",
        "frazhoj572@gmail.com",
        "info.winebarrica@gmail.com",
        "JenniferAnderson01@yahoo.com",
        "nush@richdale.com",
        "lavpatel9507@gmail.com",
        "Hitesh123@riverside.com",
        "arif@rojocarwash.com",
        "jwilliam22000@yahoo.com",
        "mccannsliquor@gmail.com",
        "varshavpatel1@yahoo.com",
        "JAGCHANI@YAHOO.COM",
        "saiquickstopboston20@gmail.com",
        "VALUEMART435@GMAIL.COM",
        "HItesh198789@gmail.com",
        "shift@belmontliquors.com",
        "sahilp26@yahoo.com",
        "naresh_0728@yahoo.con",
        "gardnergas221@gmail.com",
        "jaysquarellc@gmail.com",
        "kdp2261@gmail.com",
        "jjcigars851@gmail.com",
        "munni7754@yahoo.com",
        "gridironliquor@gmail.com",
        "alldayconv@gmail.com",
        "jay3881@hotmail.com",
        "speedgoofy@gmail.com",
        "sakarthapa1@gmail.com",
        "westendmarket74@gmail.com",
        "cornerstore296@gmail.com",
        "sunrisemarketone@gmail.com",
        "parkerst322@gmail.com",
        "peter.patel225@gmail.com",
        "javeed.pirani@gmail.com",
        "24hours@seveneleven.us",
        "mabaap06@gmail.com",
        "aamirwaq@buffalo.edu",
        "Gomez0815@hotmail.com",
        "Patel.Darshan048@gmail.com",
        "infinitysmokeshop2@gmail.com",
        "mikeliquors@gmail.com",
        "irenedominguezmx@gmail.com",
        "amdastore242@gmail.com",
        "sanketpatel9006@gmail.com",
        "hitesh1982@icloud.com",
        "ajaypatel7390@gmail.com",
        "fbumpus@lesley.edu",
        "admin@superjconline.com",
        "bpatel8515@yahoo.com",
        "oakandvariety246@Gmail.com",
        "tirth9687@gmail.com",
        "pilog71997@luxiu2.com",
        "shift1conv@gmail.com",
        "Shift2conv@gmail.com",
        "Shift3conv@gmail.com",
        "Shift4conv@gmail.com",
        "shift1@carousel.com",
        "yakshilllc@gmail.com",
        "alice@gmail.com",
        "arthapatel1997@gmai.com",
        "nvp851@gmail.com",
        "239dpshell@gmail.com",
        "Smart.stop1@yahoo.com",
        "aonepeabody@gmail.com",
        "deseka1370@mediafate.com",
        "nihoxe5134@gruppies.com",
        "vabsolutesolutions@gmail.com",
        "quickmartelberton@gmail.com",
        "shawnaivey0502@icloud.com",
        "1delibrinx@gmail.com",
        "ashwindhital0@gmail.com",
        "1delibronxny@gmail.com",
        "speedymart783@gmail.com",
        "salmandeen6436@gmail.com",
        "smart.stop2014@gmail.com",
        "sejal1178@GMAIL.COM",
        "Sejal11178@gmail.com",
        "firog@petrolafuel.com",
        "northridgeofficeplaza@hotmail.com",
        "tomyboy42@hotmail.com",
        "abc@gmail.com",
        "millcityconvenience@gmail.com",
        "sampatel20@gmail.com",
        "akkey.patel24@gmail.com",
        "1delibronx@gmail.com",
        "mehul@empireitsys.com",
        "falcon60195@gmail.com",
        "murrayp60254@gmail.com",
        "allcarerx01@gmail.com",
        "nisaradil7424@gmail.com",
        "tech@mysunshinepharmacy.com",
        "kyle.a@lotto.com",
        "kellersmarket@yahoo.com",
        "indianstore@hisably.com",
        "bernardbroo@gmail.com",
        "rakeshpatel285@gmail.com",
        "mkrc310@gmail.com",
        "kevin.s@lotto.com",
        "saraons@yahoo.com",
        "anupriya0127@icloud.com",
        "ankit234@gmail.com",
        "bleecker@playerscafe.com",
        "beyondsmokeshopss@gmail.com",
        "diana.d@lotto.vom",
        "Mukesh@linden.com",
        "jenniferking26@yahoo.com",
        "kbalasaikishore@gmail.com",
        "bythepark_store@yahoo.com",
        "braintree1station@gmail.com",
        "firstunited271@yahoo.com",
        "plympton280@gmail.com",
        "suprememart1012@gmail.com",
        "thegratemauli@gmail.com",
        "bevariety@gmail.com",
        "rangersfoji1@gmail.com",
        "vippats@outlook.com",
        "Serishar@hotmail.com",
        "dharmeshraheja@gmail.com",
        "Lakevillesmokeshop@gmail.com",
        "koxapa1790@cupbest.com",
        "lewaw@mailinator.com",
        "dleblanc1145@yahoo.com",
        "pickleworms.club@gmail.com",
        "jennifer.bennett224@gmail.com",
        "roslindalestation@gmail.com",
        "crescentliquors724@gmail.com",
        "SUPREMEFOODMART1018@GMAIL.COM",
        "joy.w@playerscafe.com",
        "Cecil.c@playerscafe.com",
        "jeffery.w@lotto.com",
        "racheal.c@playerscafe.com",
        "brenda.l@playerscafe.com",
        "jeffrey.w@lotto.com",
        "ridemostore@hisably.com",
        "fuelymart@hotmail.com",
        "mitt828@yahoo.com",
        "vatsalsmac@gmail.com",
        "patelmahendra363@gmail.com",
        "tv1306@mail.com",
        "assawa05@yahoo.com",
        "sarah.c@lotto.com",
        "tyler.h@lotto.com",
        "Vkansara@gmail.com",
        "vkansara714@gmail.com",
        "patelbob1003@gmail.com"            
    ];
    const normalizedExistingEmails = existingEmails.map(email => email.toLowerCase());
    const sendOTP = () => {
        const email = email_address.trim().toLowerCase();
        if (normalizedExistingEmails.includes(email)) {
            store.dispatch(setNewToast({
                body: 'Email already exists.',
                variant: 'warning'
            }));
            openStep1();
            return;
        }
        // Request.post(`${api_version}/register/send-otp`, {
        //     phone: phone_number,
        //     email: email_address,
        //     zip_code: address?.code
        // }).then(responseValidate).then(result => {
        //     setLoading(false);
        //     if (result.status)
        //     {
        //         store.dispatch(setNewToast({
        //             body: result.message,
        //             variant: 'success'
        //         }));
        //         setIsZipAvailable(result.data.is_zip_available);
        //         openStep2();
        //     } else {
        //         store.dispatch(setNewToast({
        //             body: result.message,
        //             variant: 'warning'
        //         }));
        //     }
        // })

        setLoading(true);
        Request.post(`${api_version}/register/verify`, {
            phone: phone_number,
            otp: '123456',
            zip_code: address?.code
        }).then(responseValidate).then(result => {
            setLoading(false);
            if (result.status)
            {
                setIsZipAvailable(result.data.is_zip_available);
                if (!result.data.is_zip_available) {
                    notifyWhenAvailable();
                } else {
                    // store.dispatch(setNewToast({
                    //     body: result.message,
                    //     variant: 'success'
                    // }));
                    openStep3();
                }
            } else {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'warning'
                }));
            }
        })
    }

    const verifyOTP = () => {
        setLoading(true);
        Request.post(`${api_version}/register/verify`, {
            phone: phone_number,
            otp: OTP,
            zip_code: address?.code
        }).then(responseValidate).then(result => {
            setLoading(false);
            if (result.status)
            {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'success'
                }));
                openStep3();
            } else {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'warning'
                }));
            }
        })
    }

    const notifyWhenAvailable = () => {
        setLoading(true);
        Request.post(`${api_version}/register/notify`, {
            phone: phone_number,
            // otp: OTP,
            otp: '123456',
            zip_code: address?.code
        }).then(responseValidate).then(result => {
            setLoading(false);
            if (result.status)
            {
                // setMessage(result.message);
                setShowNotAvailable(true);
            } else {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'warning'
                }));
            }
        })
    }

    const verifyOTPClick = () => {
        if (isZipAvailable) {
            verifyOTP();
        } else {
            notifyWhenAvailable()
        }
    }
    
    const register = () => {

        const hearAboutValue = additionalField 
        ? `${selectedOption.value} - ${additionalField}` 
        : selectedOption.value;

        if ((selectedOption?.value === 'Referred By Friend' || selectedOption?.value === 'I already have store in Hisably') && !additionalField){
            store.dispatch(setNewToast({
                body: 'Missing fields: Please check your input.',
                variant: 'warning'
            }));
            return;
        }
        Request.post(`${api_version}/register`, {
            email: email_address,
            phone: phone_number,
            first_name: firstName,
            last_name: lastName,
            store_name: storeName,
            store_street: streetAddress,
            password: password,
            password_confirmation: passwordConfirmation,
            hear_about:hearAboutValue

        }).then(responseValidate).then(result => {
            if (result.status)
            {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'success'
                }));
                setTimeout(() => window.location.reload(), 1000);
            } else {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'warning'
                }));
            }
        })
    }

    const openStep1 = () => {
        // setPhoneNumber('');
        // setEmailAddress('');
        // setAddress(null);
        // setState('');
        // setCountry('');
        setTab('step_1');
    }

    const openStep2 = () => {
        setOTP('')
        setTab('step_2');
    }

    const openStep3 = () => {
        setTab('step_3');
    }

    const redirectToMainPage = () => {
        setIsRedirect(true);
    }
    const handleSelectChange = (selected) => {
        setSelectedOption(selected);
        if (selected.value === 'Referred By Friend' || selected.value === 'I already have store in Hisably') {
            setAdditionalField('');
        } else {
            setAdditionalField(null); 
        }
    };

    if(isRedirect)
        return <Redirect to={{pathname: "/"}}/>

    return <div className="hold-transition">
        <GlobalToast/>
        <div className="registration-page register-page">
            <div className="registration-container">
                <div className="registration-card">
                    <div className="registration-left-content">
                        <div className="registration-top-content">
                            <h2 className="mb-3">Register</h2>
                            <p>Get access to Daily Reports, Invoices and Payments, Payroll and Lottery Inventory</p>
                        </div>
                        <div className="registration-bottom-content">
                            <img src={HisablyLogoWhite}/>
                        </div>
                    </div>
                    <div className="registration-right-content">
                        <Tab.Container id="registration_tabs" activeKey={tab}>
                            <Tab.Content>
                                <Tab.Pane eventKey="step_1" title="Basic Details">
                                    <div className="registration-input-content store-loaction-block d-flex">
                                        <div className="input-content-top-content">
                                            {/* <h4 className="mb-4 input-content-heading">Store location</h4> */}
                                            <Form onSubmit={e => e.preventDefault()}>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Phone Number" disabled={loading} autoFocus
                                                                  value={phone_number} onChange={e => setPhoneNumber(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="email" placeholder="Email" disabled={loading}
                                                                  value={email_address} onChange={e => setEmailAddress(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <AsyncSelect
                                                        loadOptions={pinCodeSearch} isClearable disabled={loading}
                                                        placeholder="Enter Zip Code" defaultOptions value={address}
                                                        onChange={addressInputChange} noOptionsMessage={() => 'Start typing zip code'}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Country" value={country} readOnly/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="State" value={state} readOnly/>
                                                </Form.Group>
                                                <Button variant="primary" block onClick={sendOTP} disabled={loading}>
                                                    Continue
                                                </Button>
                                            </Form>
                                        </div>
                                        <div className="input-content-bottom-content">
                                            <Link to="/" className="mt-3 text-center d-block">Already have an account? Sign in.</Link>
                                        </div>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="step_2" title="Verify OTP">
                                    <div className="registration-input-content OTP-verify-block d-flex">
                                        <Button variant="transparent back-button" onClick={openStep1}>
                                            <i className="fas fa-arrow-left"/>
                                        </Button>
                                        <div className="input-content-top-content">
                                            {/* <h4 className="mb-4 input-content-heading">Verify Phone Number</h4> */}
                                            <h6 className="mb-3 text-center">Please enter OTP to verify your Number.</h6>
                                            <span
                                                className="d-block text-center text-muted mb-5">We have sent OTP on {phone_number}, <br/> Please check message inbox</span>
                                            <Form onSubmit={e => e.preventDefault()}>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Enter OTP"
                                                                  value={OTP} onChange={e => setOTP(e.target.value)}/>
                                                </Form.Group>
                                                <Button variant="primary" block onClick={verifyOTPClick}>
                                                    Verify OTP
                                                </Button>
                                                <Link to="/" className="mt-3 text-center d-block resend-otp">Resend OTP</Link>
                                            </Form>
                                        </div>
                                        <div className="input-content-bottom-content">
                                            <Link to="/" className="mt-3 text-center d-block">
                                                Already have an account? Sign in.
                                            </Link>
                                        </div>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="step_3" title="Store Details">
                                    <div className="registration-input-content register-block d-flex">
                                        <Button variant="transparent back-button" onClick={openStep1}>
                                            <i className="fas fa-arrow-left"/>
                                        </Button>
                                        <div className="input-content-top-content">
                                            {/* <h4 className="mb-4 input-content-heading">Store location</h4> */}
                                            <Form onSubmit={e => e.preventDefault()}>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="First name"
                                                                  value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Last Name"
                                                                  value={lastName} onChange={e => setLastName(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Store Name"
                                                                  value={storeName} onChange={e => setStoreName(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Street Address"
                                                                  value={streetAddress} onChange={e => setStreetAddress(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="password" placeholder="Password"
                                                                  value={password} onChange={e => setPassword(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="password" placeholder="Confirm Password"
                                                                  value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)}/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Select 
                                                            options={options}
                                                            value={selectedOption}
                                                            isDisabled={loading}
                                                            placeholder="Select"
                                                            onChange={handleSelectChange}
                                                            getOptionLabel={option => option.label}
                                                            getOptionValue={option => option.value}
                                                        />
                                                </Form.Group>
                                                {selectedOption && additionalField !== null && (
                                                        <Form.Group>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={selectedOption?.value === 'Referred By Friend' ? "Enter Friend's Name" : 'Enter Store Name'}
                                                                value={additionalField}
                                                                onChange={e => setAdditionalField(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                                                                    
                                                <Button variant="primary" block onClick={register}>Register Now</Button>
                                            </Form>
                                        </div>
                                        <div className="input-content-bottom-content">
                                            <Link to="/login" className="mt-3 text-center d-block">Already have an account? Sign in.</Link>
                                        </div>
                                    </div>
                                </Tab.Pane>

                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
        <Modal show={showNotAvailable} onHide={() => { setShowNotAvailable(false); }} centered>
            {/* <Modal.Body>{message}</Modal.Body> */}
            <Modal.Header closeButton>
                <Modal.Title>Hisably Accounting & Lottery Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <center><p>We are currently not providing Lottery services in your state. We will notify you when available.</p></center>
                <center><p>Contact us at (617)-683-1899 to add your state.</p></center>
            </Modal.Body>
            <Modal.Footer>
                <a href="https://hisably.com" className="btn btn-primary">Continue to Hisably.com</a>
            </Modal.Footer>
        </Modal>
    </div>
}
