import React from 'react';
import moment from "moment";

const Footer = () => {

    return (
        
        <footer className="main-footer text-center text-sm d-flex justify-content-center justify-content-md-between align-items-center ">
            <span className="d-none d-md-block">© 2016 - {moment().format('YYYY')} Hisably. All Rights Reserved.</span>
            <span>Customer Support (8am to 10pm Mon-Fri) : (617) 683 1899</span>
            
        </footer>
    );
};

export default Footer;
