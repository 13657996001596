import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Modal, Row, Spinner} from "react-bootstrap";
import Subscription from "../../api/settings/Subscription";
import {responseValidate} from "../../utils/functions";
import {connect} from "react-redux";
import SaveCard from "../../modules/subscription/SaveCard";
import PaymentMethod from "../../api/settings/PaymentMethod";
import {setNewToast, setStore} from "../../store/reducers/App";
import Store from '../../store';
import Form from 'react-bootstrap/Form';
import {logOutApi} from "../../api/auth/Auth";

const UnSubscribed = ({store, stores}) => {

    const [loading, setLoading] = useState(true);
    const [subscribing, setSubscribing] = useState(false);
    const [subscriptions, setSubscription] = useState([]);
    const [saved_cards, setSavedCards] = useState([]);
    const [add_new_card, setAddNewCard] = useState(false);

    // selected state
    const [plan, setPlan] = useState();
    const [payment_card, setPaymentCard] = useState();

    const fetchSubscriptions = () => {
        return new Promise((resolve, reject) => {
            Subscription.index({
                store_id: store?.id
            }).then(responseValidate).then(result => {
                if (result.status) {
                    setSubscription(result?.data?.subscription);
                }
                resolve();
            }).catch(() => {
                reject();
            })
        })
    }

    const fetchSavedCards = () => {
        PaymentMethod.index({
            store_id: store?.id
        }).then(responseValidate).then(result => {
            if (result.status) {
                setSavedCards(result.data);
                setLoading(false)
            }
        })
    }

    const handleClose = () => {

    }

    const subscribeNow = () => {
        Subscription.store({
            store_id: store?.id,
            payment_card,
            plan
        }).then(responseValidate).then(result => {
            if (result.status) {
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                Store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'success'
                }))
            } else {
                Store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'danger'
                }))
            }
        })
    }

    useEffect(() => {
        fetchSubscriptions().then(() => {
            fetchSavedCards();
        });
    }, []);

    const switchStore = (row_id) => {
        if (row_id == "") {
            return false;
        }

        let item = localStorage.getItem("location");
        if (item) {
            localStorage.clear(); 
        }

        let row = stores.find(element => element.id == row_id);
        localStorage.setItem("location", row_id);
        Store.dispatch(setStore(row));
        window.location.reload();
    }

    return <>
        <Modal show={!add_new_card} onHide={handleClose} centered size="lg">
            <Modal.Header className="flex-wrap flex-md-nowrap">
            <Row>
                <Col md={12}>
                    <Modal.Title className="m-auto disabled-user">
                        Subscription ended! Please renew now to continue using Hisably.
                    </Modal.Title>
                </Col>
            </Row>
            </Modal.Header>
            <Modal.Body>
                {
                    loading ? <div style={{padding: '5rem', textAlign: 'center'}}>
                            <Spinner animation="border"/>
                        </div> :
                        <>
                            <div className="tab-header border-bottom pb-3 mb-4">
                                <Row>
                                    <Col md={6}>
                                        <h4 className="m-0">Subscription Plans</h4>
                                    </Col>
                                    {/* {
                                        stores.length > 1 && */}
                                        <Col md={4}>
                                            <Form.Group className="m-0">
                                                <Form.Control as="select"  onChange={(e) => switchStore(e.target.value)}>
                                                    <option value="">-- Select Store --</option>
                                                    {
                                                        stores.map(row => <option value={row?.id} selected={row?.id == store?.id ? true : false}>{row?.name}</option>)
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    {/* } */}
                                    <Col md={2}>
                                        <button className="dropdown-item" onClick={() => {
                                                logOutApi().then(() => {
                                                    window.location.reload()
                                                })
                                            }}>
                                            <i className="fas fa-sign-out-alt"/> Logout
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="subscription-container">
                                <Row className="justify-content-center">
                                    {
                                        subscriptions?.prices?.map(row => <Col key={row.id} md={6}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" onChange={e => {
                                                    if (e.target.checked)
                                                        setPlan(row.id);
                                                }} name="planRadios" id={row.id} checked={plan === row.id} disabled={subscribing}/>
                                                <label
                                                    className="form-check-label subscription-box-label shadow-2"
                                                    htmlFor={row.id}>
                                                    <h3 className="subscription-title"
                                                        style={{textTransform: 'capitalize'}}>{row.recurring.interval}ly
                                                        Plan</h3>
                                                    <span className="subscription-price">
                                                        ${row.unit_amount_decimal.substring(0, row.unit_amount_decimal.length - 2)}/{row.recurring.interval}
                                                    </span>
                                                    {
                                                        row.recurring.interval != 'month' ?
                                                        <span className="subscription-detail">Go Annual and get 10% discount!</span> : 
                                                        <span className="subscription-detail">It's only $1 per Day!</span>
                                                    }
                                                    <div className="subscription-btn">Select Plan</div>
                                                </label>
                                            </div>
                                        </Col>)
                                    }
                                </Row>
                                {
                                    saved_cards.length > 0 ? <>
                                            <div className="tab-header border-bottom pb-3 my-4">
                                                <h4 className="m-0 d-inline">
                                                    Select Card
                                                </h4>
                                                <Button className="float-right" onClick={() => setAddNewCard(true)}>
                                                    Add card
                                                </Button>
                                            </div>
                                            <Row>
                                                {
                                                    saved_cards.map(row => <Col xs={12} md={6}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio"
                                                                   checked={payment_card === row.id} name="creditCard"
                                                                   id={row.id} disabled={subscribing} onChange={e => {
                                                                       if (e.target.checked)
                                                                           setPaymentCard(row.id);
                                                                   }}/>
                                                            <label htmlFor={row.id}
                                                                   className="form-check-label subscription-box-label shadow-2">
                                                                {/*  Visa - selectable  */}
                                                                <div
                                                                    className={'credit-card selectable ' + row?.card?.brand}>
                                                                    {/*<div class="card-action-btn">*/}
                                                                    {/*    <OverlayTrigger placement="bottom" overlay={<Tooltip*/}
                                                                    {/*        id="tooltip">Delete</Tooltip>}>*/}
                                                                    {/*        <DeleteModel onYes={() => {*/}
                                                                    {/*            deleteCard(row.id)*/}
                                                                    {/*        }}>*/}
                                                                    {/*            <Button size="sm" variant="transparent"*/}
                                                                    {/*                    onClick={() => setDeleteShow(true)}><i*/}
                                                                    {/*                className="far fa-trash-alt"/></Button>*/}
                                                                    {/*        </DeleteModel>*/}
                                                                    {/*    </OverlayTrigger>*/}
                                                                    {/*</div>*/}
                                                                    <div className="credit-card-holder">
                                                                        <span className="card-label">Card Holder</span>
                                                                        <span
                                                                            className="card-value">{row?.billing_details?.name ?? 'Not found!'}</span>
                                                                    </div>
                                                                    <div className="credit-card-number">
                                                                        <span className="card-label">Card Number</span>
                                                                        <span
                                                                            className="card-value"> {row?.card?.last4}</span>
                                                                    </div>

                                                                    <div className="credit-card-expiry">
                                                                        <span className="card-label">Card Valid</span>
                                                                        <span
                                                                            className="card-value">{row?.card?.exp_month}/{row?.card?.exp_year}</span>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </Col>)
                                                }
                                            </Row>
                                        </> :
                                        <>
                                            <Card className="my-2">
                                                <Card.Header>
                                                    <Card.Title>Please add card</Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Button className="float-right d-block m-auto " onClick={() => setAddNewCard(true)}>
                                                        Add new card
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        </>
                                }
                                <Button className="mt-2" onClick={subscribeNow} block disabled={subscribing || (!plan || !payment_card)}>
                                    Subscribe Now
                                </Button>
                            </div>
                        </>
                }
            </Modal.Body>
        </Modal>
        <Modal show={add_new_card} onHide={() => setAddNewCard(false)} centered size="sm">
            <Modal.Body className="p-3">
                <SaveCard onSaved={() => {
                    fetchSavedCards();
                    setAddNewCard(false);
                }}/>
            </Modal.Body>
        </Modal>
    </>;
};

const mapStateToProps = state => ({
    store: state.App.store,
    stores: state.Auth.user.stores,
})

export default connect(mapStateToProps)(UnSubscribed);
