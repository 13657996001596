import Request from "../../utils/Request";
import {api_version} from "../../settings";

export const getUserUrl = `${api_version}/me`;

export const getStates = () => Request.get(`${api_version}/get-states`)

export async function switchStoreApi(storeId){
    await Request.post(`${api_version}/switch-store`, { store_id: storeId });
}

export async function login(email, password) {
    return await Request.get('/sanctum/csrf-cookie').then( async () => {
        return await Request.post(`${api_version}/login`, {
            email: email,
            password: password,
        })
    });
}

export async function loginwith(email, role,store_id) {
    if (role === 'Super Admin') {
        return await Request.get('/sanctum/csrf-cookie').then( async () => {
            return await Request.post(`${api_version}/loginwith`, {
                email: email,
                role: role,
                store_id:store_id
            })
        });
    }
}

export async function loginforadmin(email, role) {
    
        Request.get('/sanctum/csrf-cookie').then(() => {
            return Request.post(`${api_version}/loginforadmin`, {
                email: email,
                role: role,
            }).then(response => {
                // Check if the API response indicates success.
                window.location.href = '/';
                
                return response;
            });
        }).catch(error => {
            // Handle any errors from the CSRF or login request
            console.error('An error occurred:', error.response || error.message);
        });        
}

export async function getUser() {
    return await Request.get(getUserUrl)
}

export async function logOutApi() {
    return await Request.get(`${api_version}/logout`)
}

export async function sendOTP(phone, email, zip_code) {
    return await Request.post(`${api_version}/register/send-otp`, {
        phone,
        email,
        zip_code,
    })
}

export async function notify(phone, zip_code, otp) {
    return await Request.post(`${api_version}/register/notify`, {
        phone,
        zip_code,
        otp
    })
}

export async function verify(phone, zip_code, otp) {
    return await Request.post(`${api_version}/register/verify`, {
        phone,
        zip_code,
        otp
    })
}

export async function register(
    firstName,
    lastName,
    phone,
    store_name,
    store_street,
    postal_code_id,
    email,
    password,
    password_confirmation,
) {
    return await Request.get('/sanctum/csrf-cookie').then( async () => {
        return await Request.post(`${api_version}/register`, {
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            store_name: store_name,
            store_street: store_street,
            postal_code_id: postal_code_id,
            email: email,
            password: password,
            password_confirmation: password_confirmation,
        })
    });
}

export async function forgot_password(
    email,
) {
    return await Request.get('/sanctum/csrf-cookie').then( async () => {
        return await Request.post(`${api_version}/forgot-password`, {
            email: email,
        })
    });
}

export async function reset_password(
    email, 
    token, 
    password, 
    password_confirmation,
) {
    return await Request.get('/sanctum/csrf-cookie').then( async () => {
        return await Request.post(`${api_version}/reset-password`, {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation,
        })
    });
}

export async function change_password(
    current_password, 
    password, 
    password_confirmation,
) {
    return await Request.get('/sanctum/csrf-cookie').then( async () => {
        return await Request.post(`${api_version}/change-password`, {
            current_password: current_password,
            password: password,
            password_confirmation: password_confirmation,
        })
    });
}



export async function setDefultPassword(email){
    return await Request.get('/sanctum/csrf-cookie').then( async () => {
        return await Request.post(`${api_version}/set-default-password`, { 
            email: email 
        })
    });
}