const AppState = {
    pageName: "",
    store: null,
    shift: null,
    available_shifts: null,
    daily_report: null,
    lotto_vending: null,
    daily_scan: null,
    daily_gas: [],
    cash_drops: [],
    daily_scan_total: [],
    daily_activated_books: [],
    daily_returned_books: [],
    daily_transactions: [],
    daily_other_transactions: [],
    daily_custom_fields: [],
    saving: undefined,
    toasts: {},
    snackbar: {
        open: false,
        status: 'error',
        message: '',
        elevation: 1,
        autoHide: 2000,
        align: {
            vertical: 'top',
            horizontal: 'right'
        }
    },
    date_ranges: {},
    selected_date_range: null,
    games_data : [],
    state_calculation:[],
};

export const lotteryIssues = {
    NO_ISSUE: false,
    NOT_ACTIVATED: 1,
    NEGATIVE_QUANTITY: 2,
    NOT_SCANNED_IN_PAST_SHIFTS: 3,
};

export const getQuantity = (total_count,
                            past_ticket_number,
                            current_ticket_number,
                            returned_value,
                            is_reverse,
                            activated_deleted_at,
                            count_start
) => {
    if (activated_deleted_at !== null) {
        past_ticket_number = 0;
    }
    if (current_ticket_number >= -1 || (current_ticket_number === undefined && returned_value !== undefined)) {

        const add_extra_reverse = (count_start === 1) ? 0 : 1;

        if (is_reverse === '1') {
            return (
                // If past ticket exists or (total tickets minus 1) of book
                (past_ticket_number !== undefined ? (past_ticket_number + add_extra_reverse) : (total_count))
                // Minus
                -
                // Returned ticket number if it exists or current ticket number or 0
                (returned_value !== undefined ? (returned_value) :
                    (
                        current_ticket_number >= 0 ? (current_ticket_number + add_extra_reverse) : 0
                    ))
            );
        } else {
            if (current_ticket_number === -1) {
                if (count_start === 1) {
                    return total_count - (past_ticket_number ? past_ticket_number - 1 : 0);
                }
                return total_count - (past_ticket_number ?? count_start);
            }
            if (returned_value !== undefined) {
                return returned_value - (past_ticket_number ?? count_start);
            } else {
                if (current_ticket_number === -1) {
                    if (count_start === 1) {
                        return total_count - (past_ticket_number ? past_ticket_number - 1 : 0);
                    }
                    return total_count - (past_ticket_number ?? count_start);
                } else {
                    return (current_ticket_number ?? count_start) - (past_ticket_number ?? count_start);
                }
            }
        }
    }

    return 0;
}

export const checkLotteryTicketIssue = (shift_id, scanned_ticket, returnedTicket, previous_ticket_number, isReverseLotteryScanOrder, count_start) => {
    if (shift_id !== 0) {
        const quantity = getQuantity(
            scanned_ticket.count,
            scanned_ticket.ticket_number,
            scanned_ticket.current_ticket_number,
            returnedTicket,
            isReverseLotteryScanOrder,
            scanned_ticket.activated_deleted_at,
            count_start
        )

        if (scanned_ticket.activated_deleted_at !== null) {
            // NOT_ACTIVATED ISSUE
            return lotteryIssues.NOT_ACTIVATED;
        }

        if (shift_id !== scanned_ticket.activated_shift_id && previous_ticket_number === undefined) {
            // NOT SCANNED IN PAST SHIFTS SO NEED TO CARY FORWARD
            return lotteryIssues.NOT_SCANNED_IN_PAST_SHIFTS;
        }

        if (quantity < 0) {
            // NEGATIVE QUANTITY ISSUE
            return lotteryIssues.NEGATIVE_QUANTITY;
        }
    }

    return lotteryIssues.NO_ISSUE;
}

export const deleteShiftData = () => ({
    type: "App/DeleteShift",
})

export const setPageName = (name) => ({
    type: "App/Page",
    val: name
})

export const setStore = (store) => ({
    type: "App/Store",
    val: store
})

export const setDateRanges = (date_ranges) => ({
    type: "App/DateRanges",
    val: date_ranges
})

export const setSelectedDateRange = (selected_date_range) => ({
    type: "App/SelectedDateRange",
    val: selected_date_range
})

export const setLottoVending = (lotto_vending) => ({
    type: "App/LottoVending",
    val: lotto_vending
})
export const setShiftNull = () => ({
    type: "App/ShiftNull"
})

export const setGamesData = (games_data)=>({
    type: "App/GamesData",
    val: games_data
})

export const setStateCalculation = (state_calculation)=>({
    type: "App/StateCalculation",
    val: state_calculation
})


export const setShift = (
    shift_id,
    shift_date,
    shift_type,
    user_name,
    created_at,
    daily_report,
    lotto_vending,
    daily_scan,
    daily_gas,
    cash_drops,
    available_shifts,
    returned_books,
    activated_books,
    daily_transactions,
    daily_other_transactions,
    daily_custom_fields,
) => ({
    type: "App/Shift",
    val: {
        id: shift_id,
        date: shift_date,
        type: shift_type,
        user_name: user_name,
        created_at: created_at,
    },
    daily_report,
    lotto_vending,
    daily_scan,
    daily_gas,
    cash_drops,
    available_shifts,
    returned_books,
    activated_books,
    daily_transactions,
    daily_other_transactions,
    daily_custom_fields,
})

export const setAvailableShift = (shifts) => ({
    type: "App/AvailableShift",
    val: shifts
})

export const setDailyReport = (daily_report) => ({
    type: "App/DailyReport",
    val: daily_report
})

export const setDailyReportGas = (daily_gas) => ({
    type: "App/DailyReportGas",
    val: daily_gas
})

export const setDailyReportCashDrops = (cash_drops) => ({
    type: "App/DailyReportCashDrops",
    val: cash_drops
})

export const setDailyReturnedBooks = (daily_returned_books) => ({
    type: "App/DailyReturnedBooks",
    val: daily_returned_books
})

export const setSaving = (saving) => ({
    type: "App/Saving",
    val: saving
})

export const setDailyLotteryScans = (daily_lottery_scans) => ({
    type: "App/DailyLotteryScans",
    val: daily_lottery_scans
})

export const processLotteryTicketInReduxStore = (result, shift_id, count_start) => ({
    type: "App/ProcessDailyLotteryScans",
    result, shift_id, count_start
})

export const processLotteryTicketInReduxStoreCarryForward = (result, shift_id, count_start) => ({
    type: "App/processLotteryTicketInReduxStoreCarryForward",
    result, shift_id, count_start
})

export const processActivateBookInReduxStore = (result, shift_id, is_lotto_vending, setGameNumber= null, setBookNumber=null) => ({
    type: "App/ProcessDailyActivateBooks",
    result, shift_id, is_lotto_vending, setGameNumber, setBookNumber
})

export const clearDailyLotteryScans = () => ({
    type: "App/ClearDailyLotteryScans"
})

export const deleteDailyLotteryScans = (keys) => ({
    type: "App/DeleteDailyLotteryScans",
    keys
})

export const deleteDailyLotteryScansByInventoryId = (inventory_ids) => ({
    type: "App/DeleteDailyLotteryScansByInventoryId",
    inventory_ids
})

export const deleteDailyLotteryActivated = (keys) => ({
    type: "App/DeleteDailyLotteryActivated",
    keys
})

export const deleteDailyLotteryActivatedByInventoryId = (inventory_ids) => ({
    type: "App/DeleteDailyLotteryActivatedByInventoryId",
    inventory_ids
})

export const setDailyReportValue = (key, value, subKey = 0) => ({
    type: "App/DailyReportValue",
    val: value,
    key,
    subKey
})

export const setSnackbarMessage = (status, message, autoHide = null, elevation = 1) => ({
    type: "App/SnackBarMessage",
    val: message,
    status,
    autoHide,
    elevation
})

export const setSnackbarHidden = () => ({
    type: "App/SnackBarMessageHidden",
    val: false
})

export const setDailyScanTotal = (key, value) => ({
    type: "App/DailyScanTotal",
    key: key,
    val: value
})

export const setDailyActivatedBooks = (value) => ({
    type: "App/DailyActivatedBooks",
    val: value
})

export const setNewToast = ({title, body, time, variant = undefined, autoHide = 5000}) => ({
    type: "App/NewGlobalMessage",
    val: {
        title,
        body,
        time,
        autoHide,
        variant
    }
})

export const removeToast = (key) => ({
    type: "App/RemoveGlobalMessage",
    val: key
})

export const setDailyReportNote = (value) => ({
    type: "App/DailyReportNote",
    val: value
})

export const setStoreVendors = (store_vendors) => ({
    type: 'App/SetStoreVendors',
    val: store_vendors
})

export const setDailyTransactions = (daily_transactions) => ({
    type: 'App/SetDailyTransactions',
    val: daily_transactions
})

export const setDailyOtherTransactions = daily_other_transactions => ({
    type: 'App/SetDailyOtherTransactions',
    val: daily_other_transactions
})

export const setDailyCustomFields = daily_custom_fields => ({
    type: 'App/SetDailyCustomFields',
    val: daily_custom_fields
})

export const updateTransactionKeyValue = (index, key, val) => ({
    type: 'App/UpdateTransactionId',
    index: index,
    key: key,
    val: val
})

export const updateOtherTransactionKeyValue = (index, key, val) => ({
    type: 'App/UpdateOtherTransactionId',
    index: index,
    key: key,
    val: val
})

export const updateHouseChargesKeyValue = (index, key, val) => ({
    type: 'App/UpdateHouseChargesKeyValue',
    index: index,
    key: key,
    val: val
})

export const updateLotteryTicketNumberByInventoryId = (store_lottery_inventory_id, ticket_number) => ({
    type: 'App/UpdateLotteryTicketNumberByInventoryId',
    store_lottery_inventory_id: store_lottery_inventory_id,
    ticket_number: ticket_number,
})

export default function App(state = AppState, action) {
    switch (action.type) {
        case setPageName().type:
            return {...state, pageName: action.val};
        case setDateRanges().type:
            return {...state, date_ranges: action.val};
        case setSelectedDateRange().type:
            return {...state, selected_date_range: action.val};
        case setStore().type:
            return {
                ...state,
                store: action.val,
                shift: null,
                daily_report: null,
                daily_scan: null,
                daily_scan_total: [],
                daily_activated_books: [],
                daily_transactions: [],
            };
        case setDailyTransactions().type:
            return {...state, daily_transactions: {...action.val}};
        case setDailyOtherTransactions().type:
            return {...state, daily_other_transactions: {...action.val}};
        case setDailyCustomFields().type:
            return {...state, daily_custom_fields: action.val};
        case updateTransactionKeyValue().type:
            return {
                ...state, daily_transactions: {
                    ...state.daily_transactions,
                    [action.index]: {
                        ...state.daily_transactions[action.index],
                        [action.key]: action.val
                    }
                }
            };
        case updateOtherTransactionKeyValue().type:
            return {
                ...state, daily_other_transactions: {
                    ...state.daily_other_transactions,
                    [action.index]: {
                        ...state.daily_other_transactions[action.index],
                        [action.key]: action.val
                    }
                }
            };
        case updateHouseChargesKeyValue().type:
            return {
                ...state, daily_gas: {
                    ...state.daily_gas,
                    house_charges: {
                        ...state.daily_gas.house_charges,
                        [action.index]: {
                            ...state.daily_gas.house_charges[action.index],
                            [action.key]: action.val
                        }
                    }
                }
            };
        case updateLotteryTicketNumberByInventoryId().type: {
            const objectIndex = state.daily_scan.findIndex(function (object) {
                return object.store_lottery_inventory_id === action.store_lottery_inventory_id;
            });
            let DailyScan = [...state.daily_scan];
            if (objectIndex !== -1) {
                DailyScan[objectIndex].ticket_number = action.ticket_number;
                DailyScan.splice(0, 0, DailyScan.splice(objectIndex, 1)[0]);
            }
            return {
                ...state, daily_scan: DailyScan
            };
        }
        case setShift().type:
            return {
                ...state,
                shift: action.val,
                daily_report: action.daily_report,
                lotto_vending: action.lotto_vending,
                daily_scan: action.daily_scan,
                daily_gas: action.daily_gas,
                cash_drops: action.cash_drops,
                available_shifts: action.available_shifts,
                daily_scan_total: [],
                daily_returned_books: action.returned_books,
                daily_activated_books: action.activated_books,
                daily_transactions: action.daily_transactions,
                daily_other_transactions: action.daily_other_transactions,
                daily_custom_fields: action.daily_custom_fields,
            };
        case setShiftNull().type:
            return {
                ...state,
                shift: null,
                available_shifts: null,
                daily_report: null,
                lotto_vending: null,
                daily_scan: null,
                daily_gas: [],
                cash_drops: [],
                daily_scan_total: [],
                daily_activated_books: [],
                daily_returned_books: [],
                daily_transactions: [],
                daily_custom_fields: [],
            };
        case setLottoVending().type:
            return {
                ...state,
                lotto_vending: action.val
            }
        case setGamesData().type:
            return{
                ...state,
                games_data:action.val
            }
        case setStateCalculation().type:
            return {
                ...state,
                state_calculation:action.val

            }
        case deleteShiftData().type:
            return {
                ...state,
                shift: null,
                available_shifts: null,
                daily_report: null,
                daily_scan: null,
                daily_scan_total: [],
                daily_activated_books: [],
                daily_custom_fields: [],
            };
        case setDailyLotteryScans().type:
            return {...state, daily_scan: action.val};
        case processLotteryTicketInReduxStore().type: {
            if (state.daily_scan !== null) {
                const objectIndex = state.daily_scan.findIndex(function (object) {
                    return object.store_lottery_inventory_id === action.result.data.store_lottery_inventory_id;
                });
                // const findCurrentTicketIndex = state.daily_scan.findIndex(function (object) {
                //     return object.current_ticket_number;
                // });
                let DailyScan = [...state.daily_scan];
                let DailyActivatedBooks = [...state.daily_activated_books];
                if (objectIndex !== -1) {
                    DailyScan[objectIndex].current_ticket_number = action.result.data.ticket_number;
                    DailyScan[objectIndex].entry_type = action.result.data.entry_type;
                    DailyScan[objectIndex].scanned_id = action.result.data.scanned_id;
                    DailyScan.splice(0, 0, DailyScan.splice(objectIndex, 1)[0]);
                } else {
                    const dailyActivatedBook = state.daily_activated_books.filter(value =>
                        value.store_lottery_inventory_id === action.result.data.store_lottery_inventory_id);
                    const DailyScanObject = {...action.result.data};
                    // Not required returned ticket to send and reverse lottery scan order we are just checking carry forward
                    const lotteryIssue = checkLotteryTicketIssue(action.shift_id, action.result.data, [], action.result.ticket_number, false, action.count_start);
                    if (dailyActivatedBook.length === 0 && lotteryIssue !== lotteryIssues.NOT_SCANNED_IN_PAST_SHIFTS) {
                        const DailyActivatedObject = {...action.result.data};
                        DailyActivatedObject.entry_type = DailyActivatedObject.activated_entry_type;
                        DailyActivatedBooks.push(DailyActivatedObject);
                    }
                    DailyScanObject.ticket_number = undefined;
                    DailyScanObject.current_ticket_number = action.result.data.ticket_number;
                    DailyScan.splice(0, 0, DailyScanObject);
                }
                return {...state, daily_scan: DailyScan, daily_activated_books: DailyActivatedBooks};
            } else {
                const DailyScan = [];
                DailyScan.push(action.result.data);
                return {...state, daily_scan: DailyScan};
            }
        }
        case processLotteryTicketInReduxStoreCarryForward().type: {
            if (state.daily_scan !== null) {
                const objectIndex = state.daily_scan.findIndex(function (object) {
                    return object.store_lottery_inventory_id === action.result.store_lottery_inventory_id;
                });
                let DailyScan = [...state.daily_scan];
                if (objectIndex !== -1) {
                    DailyScan[objectIndex].current_ticket_number = action.result.ticket_number;
                    DailyScan[objectIndex].entry_type = action.result.entry_type;
                    DailyScan[objectIndex].scanned_id = action.result.scanned_id;
                    DailyScan.splice(0, 0, DailyScan.splice(objectIndex, 1)[0]);
                } else {
                    const DailyScanObject = {...action.result};
                    DailyScanObject.ticket_number = action.result.ticket_number;
                    DailyScanObject.current_ticket_number = action.result.ticket_number;
                    DailyScan.splice(0, 0, DailyScanObject);
                }
                return {...state, daily_scan: DailyScan};
            } else {
                const DailyScan = [];
                DailyScan.push(action.result);
                return {...state, daily_scan: DailyScan};
            }
        }
        case processActivateBookInReduxStore().type: {
            if (action.setGameNumber != null && action.setBookNumber != null) {
                action.setGameNumber('');
                action.setBookNumber('');
            }
            const objectIndex = state.daily_activated_books.findIndex(function (object) {
                return object.activated_id === action.result.data.activated_id;
            });
            let DailyActivatedBooks = [...state.daily_activated_books];
            let DailyScan = [...state.daily_scan];
            if (objectIndex === -1 && action.shift_id === action.result.data.shift_id) {
                const DailyActivatedBookObject = {...action.result.data};
                DailyActivatedBooks.push(DailyActivatedBookObject);
            }
            if (!action.is_lotto_vending) {
                // Not lotto vending so we can add update lottery scan (history) table
                if (action.result.data.activated_deleted_at !== '') {
                    DailyScan = state.daily_scan.filter(row => row.store_lottery_inventory_id !== action.result.data.store_lottery_inventory_id);
                }
                const objectScanIndex = DailyScan.findIndex(function (object) {
                    return object.store_lottery_inventory_id === action.result.data.store_lottery_inventory_id;
                });
                if (objectScanIndex === -1) {
                    const DailyScanBookObject = {...action.result.data};
                    DailyScanBookObject.ticket_number = undefined;
                    DailyScanBookObject.current_ticket_number = action.result.data.ticket_number;
                    DailyScan.push(DailyScanBookObject);
                } else {
                    const lotteryScanIndex = state.daily_scan.findIndex(function (object) {
                        return object.store_lottery_inventory_id === action.result.data.store_lottery_inventory_id;
                    });
                    if (lotteryScanIndex !== -1) {
                        DailyScan[lotteryScanIndex].activated_deleted_at = null;
                    }
                }
            }
            return {...state, daily_scan: DailyScan, daily_activated_books: DailyActivatedBooks};
        }
        case setDailyReturnedBooks().type:
            return {...state, daily_returned_books: action.val};
        case clearDailyLotteryScans().type:
            return {...state, daily_scan: [], daily_scan_total: []};
        case deleteDailyLotteryScans().type:
            const daily_scan = [];
            state.daily_scan.forEach(row => {
                if (action.keys.indexOf(row.scanned_id + '') === -1)
                    daily_scan.push(row);
                else if (row.ticket_number !== undefined) {
                    row.current_ticket_number = undefined;
                    daily_scan.push(row);
                }
            });
            return {...state, daily_scan};
        case deleteDailyLotteryScansByInventoryId().type:
            // const new_daily_scan = state.daily_scan.filter(row => (action.inventory_ids.indexOf(row.store_lottery_inventory_id) !== -1 && row.current_ticket_number !== -1));
            const new_daily_scan = state.daily_scan.filter(row => (action.inventory_ids.indexOf(row.store_lottery_inventory_id) === -1));
            new_daily_scan.map((row, i) => {
                if (action.inventory_ids.indexOf(row.store_lottery_inventory_id) !== -1)
                    new_daily_scan[i].activated_deleted_at = 'now';
            })
            return {...state, daily_scan: new_daily_scan};
        case deleteDailyLotteryActivatedByInventoryId().type:
            const new_daily_activated_books = state.daily_activated_books.filter(row => (action.inventory_ids.indexOf(row.store_lottery_inventory_id) === -1));
            new_daily_activated_books.map((row, i) => {
                if (action.inventory_ids.indexOf(row.store_lottery_inventory_id) !== -1)
                    new_daily_activated_books[i].activated_deleted_at = 'now';
            })
            return {...state, daily_activated_books: new_daily_activated_books};
        case deleteDailyLotteryActivated().type:
            const daily_activated_books = state.daily_activated_books.filter(row => action.keys.indexOf(row.activated_id + '') === -1);
            return {...state, daily_activated_books};
        case setDailyActivatedBooks().type:
            return {...state, daily_activated_books: action.val};
        case setDailyScanTotal().type:
            return {
                ...state, daily_scan_total: {
                    ...state.daily_scan_total,
                    [action.key]: action.val
                }
            };
        case setDailyReport().type:
            return {...state, daily_report: action.val};
        case setDailyReportGas().type:
            return {...state, daily_gas: action.val};
        case setDailyReportCashDrops().type:
            return {...state, cash_drops: action.val};
        case setSaving().type:
            return {...state, saving: action.val};
        case setNewToast({}).type:
            const index = Object.keys(state.toasts).length;
            return {
                ...state, toasts: {
                    ...state.toasts,
                    [index]: action.val
                }
            }
        case removeToast().type:
            const oldToasts = {...state.toasts};
            delete oldToasts[action.val];
            return {...state, toasts: {...oldToasts}}
        case setSnackbarMessage().type:
            return {
                ...state, snackbar: {
                    ...state.snackbar,
                    open: true,
                    status: action.status,
                    message: action.val,
                    elevation: action.elevation,
                    autoHide: action.autoHide
                }
            };
        case setSnackbarHidden().type:
            return {
                ...state, snackbar: {
                    ...state.snackbar,
                    open: false
                }
            };
        case setDailyReportValue().type: {
            const {key, subKey, val} = action;
            return {
                ...state, daily_report: {
                    ...state.daily_report,
                    values: {
                        ...state.daily_report.values,
                        [key]: {
                            ...state.daily_report.values[key],
                            [subKey]: {
                                ...state.daily_report.values[key][subKey],
                                value: val
                            }
                        }
                    }
                }
            };
        }
        case setDailyReportNote().type: {
            return {
                ...state, daily_report: {
                    ...state.daily_report,
                    note: action.value
                }
            };
        }
        case setStoreVendors().type:
            return {
                ...state,
                store: {
                    ...state.store,
                    store_vendors: action.val
                }
            }
        default:
            return state;
    }
}
